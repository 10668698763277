<script setup>
import Checkbox from '@/Forms/Inputs/Checkbox.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import InputError from '@/Forms/Inputs/InputError.vue';
import InputLabel from '@/Forms/Inputs/InputLabel.vue';
import PrimaryButton from '@/Elements/PrimaryButton.vue';
import TextInput from '@/Forms/Inputs/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import { ref } from 'vue';

defineProps({
  canResetPassword: Boolean,
  status: String,
  errors: Array
});

const form = useForm({
  email: '',
  password: '',
  remember: false
});

const loginType = ref('magic');

const submit = () => {
  if (loginType.value === 'magic') {
    form.post(route('passwordless.create'), {
      onFinish: () => form.reset('password')
    });
    return;
  }

  form.post(route('login'), {
    onFinish: () => form.reset('password')
  });
};

const toggleLoginType = () => {
  loginType.value = loginType.value === 'magic' ? 'password' : 'magic';
};
</script>

<template>
  <AuthLayout>
    <Head title="Log in" />

    <div
      v-if="status"
      class="mb-4 bg-green-100 text-sm rounded p-2 px-3 font-medium text-green-700"
    >
      {{ status }}
    </div>

    <div v-for="(error, key) in errors" :key="key" class="space-y-1">
      <div
        v-if="error"
        class="mb-4 bg-red-100 text-sm rounded p-2 px-3 font-medium text-red-700"
      >
        {{ error }}
      </div>
    </div>

    <form @submit.prevent="submit">
      <div>
        <InputLabel for="email" value="Email" />

        <TextInput
          id="email"
          type="email"
          class="mt-1 block w-full"
          v-model="form.email"
          required
          autofocus
          autocomplete="username"
        />

        <InputError class="mt-2" :message="form.errors.email" />
      </div>

      <div class="mt-4" v-if="loginType !== 'magic'">
        <InputLabel for="password" value="Password" />

        <TextInput
          id="password"
          type="password"
          class="mt-1 block w-full"
          v-model="form.password"
          required
          autocomplete="current-password"
        />

        <InputError class="mt-2" :message="form.errors.password" />
      </div>

      <div class="block mt-4" v-if="loginType !== 'magic'">
        <label class="flex items-center">
          <Checkbox name="remember" v-model:checked="form.remember" />
          <span class="ml-2 text-sm text-gray-600 dark:text-gray-400"
            >Remember me</span
          >
        </label>
      </div>

      <div
        class="flex items-center mt-4"
        :class="
          canResetPassword && loginType !== 'magic'
            ? 'justify-between'
            : 'justify-end'
        "
      >
        <Link
          v-if="canResetPassword && loginType !== 'magic'"
          :href="route('password.request')"
          class="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
        >
          Forgot your password?
        </Link>

        <PrimaryButton
          class="ml-4"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
          v-text="loginType === 'magic' ? 'Send login link' : 'Log in'"
        >
        </PrimaryButton>
      </div>

      <div class="mt-6">
        <div class="relative flex justify-center text-sm">
          <a
            class="bg-white px-2 text-gray-500 underline cursor-pointer"
            @click="toggleLoginType"
            v-text="loginType === 'magic' ? 'Use a password' : 'Use magic link'"
          >
          </a>
        </div>
      </div>

      <div class="mt-6">
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="bg-white px-2 text-gray-500">Or continue with</span>
          </div>
        </div>

        <div class="mt-6 grid grid-cols-2 gap-3">
          <div>
            <a
              :href="route('auth.oauth.redirect', 'google')"
              class="inline-flex w-full justify-center rounded-md bg-white py-2 px-4 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
            >
              <span class="sr-only">Sign in with Google</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="w-8 h-8"
                viewBox="0 0 16 16"
              >
                <path
                  d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"
                />
              </svg>
            </a>
          </div>

          <div>
            <a
              :href="route('auth.oauth.redirect', 'microsoft')"
              class="inline-flex w-full justify-center rounded-md bg-white py-2 px-4 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
            >
              <span class="sr-only">Sign in with Microsoft</span>
              <svg
                id="icons"
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M31.87,30.58H244.7V243.39H31.87Z" />
                <path d="M266.89,30.58H479.7V243.39H266.89Z" />
                <path d="M31.87,265.61H244.7v212.8H31.87Z" />
                <path d="M266.89,265.61H479.7v212.8H266.89Z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </form>
  </AuthLayout>
</template>
